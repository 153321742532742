import './App.css';
import './components/assets/css/blue.css';
import Epslogin from './pages/Epslogin';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import Epshome from './pages/Epshome';
import Espdetail from './pages/Espdetail';
function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Epslogin />} />
        <Route path='/ehsdashboard' element={<Epshome />} />
        <Route path='/ehsdetail' element={<Espdetail />} />
      </Routes>
    </>
  );
}

export default App;
