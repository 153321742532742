import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Happiness from '../components/Happiness';
import Dimensions from '../components/Dimensions';
import Myworkexperience, { Complaint, Intent, Jobsatisfaction, Myteam, Seniormanagement, Teamlead, Theorganization } from '../components/Myworkexperience';
import { url } from '../env';

function Espdetail() {
  const [currentSection, setCurrentSection] = useState(1);
  const [workExperienceAnswers, setWorkExperienceAnswers] = useState({});
  const [teamAnswers, setTeamAnswers] = useState({});
  const [theorganizationAnswers, setTheorganizationAnswers] = useState({});
  const [teamleadAnswers, setTeamleadAnswers] = useState({});
  const [seniormanagementAnswers, setSeniormanagementAnswers] = useState({});
  const [jobsatisfactionAnswers, setJobsatisfactionAnswers] = useState({});
  const [complaintAnswers, setComplaintAnswers] = useState({});
  const [intentAnswers, setIntentAnswers] = useState({});
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.style.backgroundColor = 'rgb(25,25,25)';
    document.body.style.backgroundColor = 'rgb(25,25,25)';
    document.body.style.background = ' linear-gradient(359deg, rgba(25,25,25,1) 8%, rgba(59,15,146,1) 40%, rgba(25,25,25,1) 96%)';

    return () => {
      document.documentElement.style.backgroundColor = '';
      document.body.style.backgroundColor = '';
    };
  }, []);

  // Scroll to the section when `currentSection` changes
  useEffect(() => {
    const sectionId = `form-section-${currentSection}`;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentSection]);

  const handleWorkExperienceSubmit = useCallback((updatedAnswers, comments) => {
    setWorkExperienceAnswers({ answers: updatedAnswers, comments: comments });
  }, []);

  const handleTeamSubmit = useCallback((updatedAnswers, comments) => {
    setTeamAnswers({ answers: updatedAnswers, comments });
  }, []);

  const handleTheorganizationSubmit = useCallback((updatedAnswers, comments) => {
    setTheorganizationAnswers({ answers: updatedAnswers, comments });
  }, []);

  const handleTeamleadSubmit = useCallback((updatedAnswers, comments) => {
    setTeamleadAnswers({ answers: updatedAnswers, comments });
  }, []);

  const handleSeniormanagementSubmit = useCallback((updatedAnswers, comments) => {
    setSeniormanagementAnswers({ answers: updatedAnswers, comments });
  }, []);

  const handleJobsatisfactionSubmit = useCallback((updatedAnswers, comments) => {
    setJobsatisfactionAnswers({ answers: updatedAnswers, comments });
  }, []);

  const handleComplaintSubmit = useCallback((updatedAnswers, comments) => {
    setComplaintAnswers({ answers: updatedAnswers, comments });
  }, []);

  const handleIntentSubmit = useCallback((updatedAnswers, comments) => {
    setIntentAnswers({ answers: updatedAnswers, comments });
  }, []);

  const isWorkExperienceComplete = () => {
    const requiredQuestions = 11;
    const answersArray = workExperienceAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && workExperienceAnswers.comments && workExperienceAnswers.comments.trim() !== '';
  };

  const isTeamComplete = () => {
    const requiredQuestions = 9;
    const answersArray = teamAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && teamAnswers.comments && teamAnswers.comments.trim() !== '';
  };

  const isOrganizationComplete = () => {
    const requiredQuestions = 9;
    const answersArray = theorganizationAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && theorganizationAnswers.comments && theorganizationAnswers.comments.trim() !== '';
  };

  const isTeamLeadComplete = () => {
    const requiredQuestions = 14;
    const answersArray = teamleadAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && teamleadAnswers.comments && teamleadAnswers.comments.trim() !== '';
  };

  const isSeniorManagementComplete = () => {
    const requiredQuestions = 10;
    const answersArray = seniormanagementAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && seniormanagementAnswers.comments && seniormanagementAnswers.comments.trim() !== '';
  };

  const isJobSatisfactionComplete = () => {
    const requiredQuestions = 7;
    const answersArray = jobsatisfactionAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && jobsatisfactionAnswers.comments && jobsatisfactionAnswers.comments.trim() !== '';
  };

  const isComplaintComplete = () => {
    const requiredQuestions = 2;
    const answersArray = complaintAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && complaintAnswers.comments && complaintAnswers.comments.trim() !== '';
  };

  const isIntentComplete = () => {
    const requiredQuestions = 5;
    const answersArray = intentAnswers.answers || [];
    const allAnswered = answersArray.length === requiredQuestions &&
      answersArray.every(answer => answer.answer && answer.answer.trim() !== '');
    return allAnswered && intentAnswers.comments && intentAnswers.comments.trim() !== '';
  };

  const validateSection = () => {
    switch (currentSection) {
      case 1:
      case 2:
        return true;
      case 3:
        return isWorkExperienceComplete();
      case 4:
        return isTeamComplete();
      case 5:
        return isOrganizationComplete();
      case 6:
        return isTeamLeadComplete();
      case 7:
        return isSeniorManagementComplete();
      case 8:
        return isJobSatisfactionComplete();
      case 9:
        return isComplaintComplete();
      case 10:
        return isIntentComplete();
      default:
        return false;
    }
  };

  const handleNextSection = (section) => {
    if (validateSection()) {
      setError(null);
      setCurrentSection(section);
    } else {
      setError('Please answer all questions in this section before proceeding.');
    }
  };

  const employeeCode = location.state?.employeeCode;
  const department = location.state?.department;
  const email = location.state?.email;
  const any = location.state?.anonymous;

  const handleFinalSubmit = async () => {
    if (validateSection()) {
      setError(null);

      const data = {
        employee_code: employeeCode,
        department: department,
        email: email,
        anonymous: any,
        work_experience: workExperienceAnswers,
        team_experience: teamAnswers,
        organization: theorganizationAnswers,
        team_lead: teamleadAnswers,
        senior_management: seniormanagementAnswers,
        job_satisfaction: jobsatisfactionAnswers,
        complaints_and_concerns: complaintAnswers,
        intent_to_stay: intentAnswers,
        form_submitted: true,
      };

      console.log('All data:', data);
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage = errorData.message || 'An error occurred. Please try again.';
          setResponseMessage(errorMessage);
          return;
        }
        const result = await response.json();
        console.log('API success:', result);
        if (result.message.includes('successfully')) {
          setSuccessMessage('Thanks For Your Feedback');
          setCurrentSection(11);
          setTimeout(() => {
            window.location = '/';
          }, 2000);
        }
      } catch (error) {
        console.error('Error adding role:', error);
      }
    } else {
      setError('Please answer all questions in this section before proceeding.');
    }
  };

  return (
    <div className="container">
      <div className="submenu"></div>
      {/* Section 1 */}
      {currentSection === 1 && (
        <div className="row my-4" id="form-section-1" style={{ justifyContent: 'center' }}>
          <Happiness />
          <div className="row" style={{ justifyContent: 'end' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(2)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 2 */}
      {currentSection === 2 && (
        <div className="row my-4" id="form-section-2" style={{ justifyContent: 'center' }}>
          <Dimensions />
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(1)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(3)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 3 */}
      {currentSection === 3 && (
        <div className="row my-4" id="form-section-3" style={{ justifyContent: 'center' }}>
          <Myworkexperience
            onSubmit={handleWorkExperienceSubmit}
            setValidationError={(message) => setError(message)}
          />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(2)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(4)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 4 */}
      {currentSection === 4 && (
        <div className="row my-4" id="form-section-4" style={{ justifyContent: 'center' }}>
          <Myteam handleChange={handleTeamSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(3)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(5)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 5 */}
      {currentSection === 5 && (
        <div className="row my-4" id="form-section-5" style={{ justifyContent: 'center' }}>
          <Theorganization handleChange={handleTheorganizationSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(4)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(6)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 6 */}
      {currentSection === 6 && (
        <div className="row my-4" id="form-section-6" style={{ justifyContent: 'center' }}>
          <Teamlead handleChange={handleTeamleadSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(5)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(7)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 7 */}
      {currentSection === 7 && (
        <div className="row my-4" id="form-section-7" style={{ justifyContent: 'center' }}>
          <Seniormanagement handleChange={handleSeniormanagementSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(6)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(8)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 8 */}
      {currentSection === 8 && (
        <div className="row my-4" id="form-section-8" style={{ justifyContent: 'center' }}>
          <Jobsatisfaction handleChange={handleJobsatisfactionSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(7)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(9)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 9 */}
      {currentSection === 9 && (
        <div className="row my-4" id="form-section-9" style={{ justifyContent: 'center' }}>
          <Complaint handleChange={handleComplaintSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(8)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => handleNextSection(10)}>Next</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 10 */}
      {currentSection === 10 && (
        <div className="row my-4" id="form-section-10" style={{ justifyContent: 'center' }}>
          <Intent handleChange={handleIntentSubmit} setValidationError={(message) => setError(message)} />
          {error && <div className="alert alert-danger">{error}</div>}
          {responseMessage && <div className="alert alert-danger">{responseMessage}</div>}
          <div className="row" style={{ justifyContent: 'space-between' }}>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={() => setCurrentSection(9)}>Prev</button>
            </div>
            <div className="col-md-1">
              <button type="button" className="active-btn" onClick={handleFinalSubmit}>Finish</button>
            </div>
          </div>
        </div>
      )}
      {/* Section 11 */}
      {currentSection === 11 && (
        <div className="row my-4" id="form-section-11" style={{ justifyContent: 'center' }}>
          <h2 style={{ color: 'white', textAlign: 'center' }}>{successMessage}</h2>
        </div>
      )}
    </div>
  );
}

export default Espdetail; 
