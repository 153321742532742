import React from 'react'
import dimentions from './assets/img/dimentions.PNG';
import scroing from './assets/img/scoring.PNG';
function Dimensions() {
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: 'white' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Dimensions of Engagement </h2>
          <p style={{ marginBottom: '0' }}>To assess employee engagement, responses will be collected across eight dimensions, covering key factors essential for employees to remain engaged in the workplace. The numbers represent the total number of questions in each section.</p>
          <hr />
          <div className="img-responsive">
          <img src={dimentions} alt="" style={{width:'80%'}} />
          </div>
        </div>
        <div className="card-block" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Scoring </h2>
          <p style={{ margin: '3% 10%' }}>
          There are a total of 76 questions, and employees are required to express their agreement with the given statements/questions using a five-point scale ranging from ‘strongly agree’ to ‘strongly disagree.’ Almost all of the questions, except for a few in the ‘intent to stay’ category, are framed from a positive perspective. Therefore, it is reasonable to conclude that the higher the score, the greater the degree of engagement/satisfaction.
          </p>
          <hr />
          <div className="img-responsive">
          <img src={scroing} alt="" style={{width:'80%'}} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Dimensions