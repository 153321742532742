import React from 'react'
import { Link} from 'react-router-dom';
import icon1 from '../components/assets/img/logo-light-icon.png';
import user1 from '../components/assets/img/1.jpg';
import { NavLink } from 'react-router-dom';

function Epshome() {
  return (
    <>
    <style>
        {`
          .disabled-link {
            pointer-events: none; 
            color: gray; 
            cursor: not-allowed; 
            opacity: 0.6; 
          }
        `}
      </style>
     <header className="topbar">
        <nav className="navbar navbar-expand-lg top-navbar navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand">
              <img src={icon1} alt="homepage" className="light-logo" style={{ marginBottom: '10px' }} />
              <span className="logo-text">chat Pandas</span>
            </Link>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item" style={{ display: 'none' }}>
                </li>
                <li className="nav-item hidden-sm-down search-box">
                </li>
              </ul>
              <ul className="navbar-nav my-lg-0">
                <li className="nav-item dropdown">
                  <Link className="nav-link" id="navbarDropdown" role="button">
                    <img src={user1} alt="user" className="profile-pic m-r-10" /> <span className='profile-name'>Ahmad Hassan</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <nav className="navbar navbar-expand-lg">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample08"
          aria-controls="navbarsExample08" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarsExample08">
          <ul className="navbar-nav nav-wrap">
            <li className="nav-item disabled-link">
              <NavLink className='nav-link' to="/dashboard" >Dashboard</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to="/profile">Profile</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to="/leave">Leave</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to="/attendancedetail">Attendance</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to='/organization'>Organization
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to='/hiring'>Hiring</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to='/payroll'>Payroll</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to='/reports'>Reports</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to='/documentation'>Documentation & Letters </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link disabled-link' to='/email'>Email </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className='nav-link' to='/ehsdetail'>EHS</NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  )
}

export default Epshome