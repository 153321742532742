import React, { useState, useEffect } from 'react';
import Questionstr from './Questionstr';
import questions1, { questions2, questions3, questions4, questions5, questions6, questions7, questions8 } from './Question';
function Myworkexperience({ onSubmit, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions1.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});

    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    onSubmit(formatAnswersToArray(answers), comments);
  }, [answers, comments, onSubmit]);

  const handleChange = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  // if (comments.trim() === '') {
  // document.getElementById('ehstextares').style.border= '1px solid red';
  // } 
  // else {
  //   setValidationError(null);
  // }
  // }, [comments, setValidationError]);
  return (
    <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
      <div className="card-title" style={{ textAlign: 'center' }}>
        <h2 className="card-title">My Work Experience</h2>
        <p>
          In this section, we’d like to understand your perception of working with ChatPandas. There are 11 questions here that aim to capture your experiences, insights, and overall impressions. Your feedback is invaluable in helping us build a supportive and rewarding workplace, so please take a moment to share your honest thoughts below.
        </p>
        <hr />
      </div>
      {questions1.map((question) => (
        <Questionstr
          key={question.number}
          number={question.number}
          text={question.text}
          name={question.name}
          handleChange={handleChange}
        />
      ))}
      <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
      <textarea
        className="form-control my-3"
        placeholder="Comments"
        name="comments"
        id="ehstextares"
        rows="10"
        col="10"
        value={comments}
        onChange={handleCommentChange}
      ></textarea>
    </div>
  );
}
export function Myteam({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions2.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };

  return (
    <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
      <div className="card-title" style={{ textAlign: 'center' }}>
        <h2 className="card-title">My Team</h2>
        <p>In this section, please share your agreement or disagreement with various dynamics within your team. There are a total of 9 questions to complete in this section.</p>
        <hr />
      </div>
      {questions2.map((question) => (
        <Questionstr
          key={question.number}
          number={question.number}
          text={question.text}
          name={question.name}
          handleChange={handleChangeLocal}
        />
      ))}
      <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
      <textarea
        className="form-control my-3"
        placeholder="Comments"
        name="comments"
        id="ehstextares"
        rows="10"
        col="10"
        value={comments}
        onChange={handleCommentChange}
      ></textarea>
    </div>
  );
}

export function Theorganization({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions3.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };

  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">The Organization</h2>
          <p>In this section, please share your agreement or disagreement with various aspects of the organization. There are a total of 9 questions to complete in this section.
          </p>
          <hr />
        </div>
        {questions3.map((question) => (
          <Questionstr
            key={question.number}
            number={question.number}
            text={question.text}
            name={question.name}
            handleChange={handleChangeLocal}
          />
        ))}
        <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
        <textarea
          className="form-control my-3"
          placeholder="Comments"
          name="comments"
          id="ehstextares"
          rows="10"
          col="10"
          value={comments}
          onChange={handleCommentChange}
        ></textarea>
      </div>
    </>
  );
}


export function Teamlead({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions4.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };

  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Team Lead </h2>
          <p>In this section, please share your agreement or disagreement regarding your team lead or supervisor. There are a total of 14 questions to complete in this section.</p>
          <hr />
        </div>
        {questions4.map((question) => (
          <Questionstr
            key={question.number}
            number={question.number}
            text={question.text}
            name={question.name}
            handleChange={handleChangeLocal}
          />
        ))}
        <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
        <textarea
          className="form-control my-3"
          placeholder="Comments"
          name="comments"
          id="ehstextares"
          rows="10"
          col="10"
          value={comments}
          onChange={handleCommentChange}
        ></textarea>
      </div>
    </>
  );
}
export function Seniormanagement({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions5.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Senior Management</h2>
          <p>
            In this section, please share your perception of the senior leadership team of the organization. There are a total of 10 questions to complete in this section.
          </p>
          <hr />
        </div>
        {questions5.map((question) => (
          <Questionstr
            key={question.number}
            number={question.number}
            text={question.text}
            name={question.name}
            handleChange={handleChangeLocal}
          />
        ))}
        <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
        <textarea
          className="form-control my-3"
          placeholder="Comments"
          name="comments"
          id="ehstextares"
          rows="10"
          col="10"
          value={comments}
          onChange={handleCommentChange}
        ></textarea>
      </div>
    </>
  );
}
export function Jobsatisfaction({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions6.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Job Satisfaction</h2>
          <p>
            This section explores employee satisfaction with their jobs. There are a total of 7 questions in this section, with key highlights as follows:
          </p>
          <hr />
        </div>
        {questions6.map((question) => (
          <Questionstr
            key={question.number}
            number={question.number}
            text={question.text}
            name={question.name}
            handleChange={handleChangeLocal}
          />
        ))}
        <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
        <textarea
          className="form-control my-3"
          placeholder="Comments"
          name="comments"
          id="ehstextares"
          rows="10"
          col="10"
          value={comments}
          onChange={handleCommentChange}
        ></textarea>
      </div>
    </>
  );
}
export function Complaint({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions7.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };

  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Complaints and Grievances</h2>
          <p>
            This section gathers information on any complaints or grievances you may have.
          </p>
          <hr />
        </div>
        {questions7.map((question) => (
          <Questionstr
            key={question.number}
            number={question.number}
            text={question.text}
            name={question.name}
            handleChange={handleChangeLocal}
          />
        ))}
        <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
        <textarea
          className="form-control my-3"
          placeholder="Comments"
          name="comments"
          id="ehstextares"
          rows="10"
          col="10"
          value={comments}
          onChange={handleCommentChange}
        ></textarea>
      </div>
    </>
  );
}
export function Intent({ handleChange, setValidationError }) {
  const [comments, setComments] = useState('');
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    const initialAnswers = questions8.reduce((acc, question) => {
      acc[question.name] = { question: question.text, answer: '' };
      return acc;
    }, {});
    setAnswers(initialAnswers);
  }, []);

  useEffect(() => {
    handleChange(formatAnswersToArray(answers), comments);
  }, [answers, comments, handleChange]);

  const handleCommentChange = (e) => {
    setComments(e.target.value);
  };

  // useEffect(() => {
  //   if (comments.trim() === '') {
  //     setValidationError('Comments cannot be empty.');
  //   } else {
  //     setValidationError(null);
  //   }
  // }, [comments, setValidationError]);

  const handleChangeLocal = (name, value) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value },
    }));
  };

  const formatAnswersToArray = (answers) => {
    return Object.values(answers);
  };
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: '#e7dfcd' }}>
        <div className="card-title" style={{ textAlign: 'center' }}>
          <h2 className="card-title">Intent to Stay</h2>
          <p>
          This section explores your openness to new job opportunities and any thoughts you may have about staying with or leaving the organization. Please answer the 5 questions below.
          </p>
          <hr />
        </div>
        {questions8.map((question) => (
          <Questionstr
            key={question.number}
            number={question.number}
            text={question.text}
            name={question.name}
            handleChange={handleChangeLocal}
          />
        ))}
        <p className='my-4'> <b>To proceed, please add your thoughts in the comment section. If you have no specific comments, simply write 'N/A.</b> </p>
        <textarea
          className="form-control my-3"
          placeholder="Comments"
          name="comments"
          id="ehstextares"
          rows="10"
          col="10"
          value={comments}
          onChange={handleCommentChange}
        ></textarea>
      </div>
    </>
  );
}
export default Myworkexperience;