 import React from 'react';

function Question({ number, text, name, handleChange }) {
  return (
    <div className="card-block">
      <p id={`${name}${number}`} style={{ fontWeight: '700' }}>
        {number}. {text}
      </p>
      <input
        type="radio"
        name={name}
        value="stronglyagree"
        id={`stronglyagree${number}`}
        onChange={(e) => handleChange(name, e.target.value)}
      />
      <label htmlFor={`stronglyagree${number}`} style={{ marginLeft: '5px', fontWeight: '300' }}>
        Strongly Agree
      </label>

      <input
        type="radio"
        name={name}
        value="agree"
        id={`agree${number}`}
        style={{ marginLeft: '15px' }}
        onChange={(e) => handleChange(name, e.target.value)}
      />
      <label htmlFor={`agree${number}`} style={{ marginLeft: '5px', fontWeight: '300' }}>
        Agree
      </label>

      <input
        type="radio"
        name={name}
        value="neutral"
        id={`neutral${number}`}
        style={{ marginLeft: '15px' }}
        onChange={(e) => handleChange(name, e.target.value)}
      />
      <label htmlFor={`neutral${number}`} style={{ marginLeft: '5px', fontWeight: '300' }}>
        Neutral
      </label>

      <input
        type="radio"
        name={name}
        value="disagree"
        id={`disagree${number}`}
        style={{ marginLeft: '15px' }}
        onChange={(e) => handleChange(name, e.target.value)}
      />
      <label htmlFor={`disagree${number}`} style={{ marginLeft: '5px', fontWeight: '300' }}>
        Disagree
      </label>

      <input
        type="radio"
        name={name}
        value="stronglydisagree"
        id={`stronglydisagree${number}`}
        style={{ marginLeft: '15px' }}
        onChange={(e) => handleChange(name, e.target.value)}
      />
      <label htmlFor={`stronglydisagree${number}`} style={{ marginLeft: '5px', fontWeight: '300' }}>
        Strongly Disagree
      </label>
    </div>
  );
}

export default Question;
