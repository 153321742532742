import React from 'react'
import esplogo from '../components/assets/img/esplogo.svg';

function Happiness() {
  return (
    <>
      <div className="col-md-10" style={{ margin: '0 auto', color: 'white' }}>
        <div className="card-title" style={{ textAlign: 'center', margin: '5% 0' }}>
          <h1 className="card-title">Employee Happiness Survey 2024</h1>
          <p style={{ marginBottom: '0', marginTop: '8px' }}>Human Resource Department</p>
          <hr />
          <img src={esplogo} alt="" />
        </div>
        <div className="card-block" style={{ textAlign: 'center', margin: '5% 0' }}>
          <p style={{ margin: '3% 10%' }}>
            At ChatPandas, we believe that our team’s insights and experiences drive our success. This survey is designed to gather your honest feedback on various aspects of our workplace—from our work environment and resources to communication, growth opportunities, and support. Your responses are completely anonymous and will help us identify strengths and areas for improvement, allowing us to shape a positive and productive workplace. <br />
            Thank you for taking the time to share your thoughts and contribute to our continuous improvement journey!

          </p>
          <hr />
        </div>
      </div>
    </>
  )
}

export default Happiness